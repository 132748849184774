<template>
  <v-card 
    outlined 
    density="compact"
    class="bg-teal mt-3 mb-5" >
    <v-card-text>


      <v-form @submit.prevent="addAReason" density="compact">
        <v-textarea 
          clearable
          auto-grow
          density="compact"
          bg-color="white"
          v-model.trim="newReason"
        ></v-textarea>
        <v-btn class="bg-green" block type="submit" :disabled="invalidReason"><span class="text-white">Add this Vision</span></v-btn>
      </v-form>
    </v-card-text>


  </v-card>
</template>

<script>
import { addNewReason } from '../../services/ReasonsServices.js';

export default {
  props: [ 'uId', 'token', 'todayActual' ], 
  data() {
    return {
      newReason: "what's a detail about your dream life? visualize it in your mind, in vivid detail. FEEL as if you already have it, done it, are it.", 
    };
  },
  computed: {
    today() {
      return this.$store.getters["today"];
    },
    invalidReason() {
      return this.newReason.length > 0 ? false : true;
    },
  },
  methods: {
    async addAReason() {
      const success = await addNewReason(this.uId, this.token, this.todayActual, this.newReason);
      this.newReason = "";
      console.log('results of addAReason:', success);

      // const newReason = {
      //   reason: this.newReason,
      //   dateCreated: this.today,
      //   dateUpdated: this.today,
      // };
      // this.$store.dispatch("reasons/addNewReason", newReason);

    },
  },
};
</script>

