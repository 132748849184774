/** REASONS SERVICES */
/** REASONS SERVICES */
/** REASONS SERVICES */

// CREATE A REASON - returns true if create is successful, or throws error.
export async function addNewReason(uId, token, today, reason) {
  console.log('starting addNewReason()');
  // build the data 
  const newReason = {
    reason,
    dateCreated: today,
    dateUpdated: today,
  };
  // REST API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(newReason)
  });
  const responseData = await response.json();
  // "err handling"
  if (!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to add a new Reason');
    throw error;
  } else {
    return true;
  }
}


// READ ALL REASONS - returns an array of reasons, or empty array.
export async function getAllReasonsFromDbase(uId, token) {
  console.log('starting getAllReasonsFromDbase()');
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons.json?auth=${token}`);
  // error handling
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to get all Reasons');
    throw error;
  }
  // manipulate & return data
  const responseData = await response.json();
  let reasons = [];
  for (const key in responseData) {
    const reasonObj = {
      id: key,
      reason: responseData[key].reason,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    reasons.push(reasonObj);
  }
  return reasons;
}


// READ ONE REASON - returns one reason object, or false.
export async function getOneReasonFromDbase(uId, token, rId) {
  console.log('starting getOneReasonFromDbase()');
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons/${rId}.json?auth=${token}`);
  // error handling
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to get one Reason');
    throw error;
  }
  // manipulate & return data
  const r = await response.json();

  if (r) {
    const reason = {
      reason: r.reason,
      dateCreated: r.dateCreated,
      dateUpdated: r.dateUpdated,
    };
    return reason;
  } else {
    return false;
  }
}


// UPDATE A REASON - returne true update is successful, or throws error
export async function updateReason(uId, token, todayActual, rId, reason) {
  console.log('starting updateReason()');
  const updatedReason = {
    reason,
    dateUpdated: todayActual,
  }
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons/${rId}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify(updatedReason)
  });
  // get the response
  const responseData = await response.json();
  if(!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to update a Reason');
    throw error;
  } else {
    return true;
  }
}


// DELETE A REASON - returns true if delete is successful, or error
export async function deleteReason(uId, token, reasonId) {
  console.log('starting deleteReason()');
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons/${reasonId}.json?auth=${token}`, {
    method: 'DELETE',
  });
  // response
  const responseData = await response.json();
  if (!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to delete a Reason');
    throw error;
  } else {
    return true;
  }
}


