<template>
  <v-card outlined density="compact" class="bg-teal mt-3 mb-5" >
    <v-card-title class="text-decoration-underline py-3">Add a new whatDo</v-card-title>
    <v-card-text>

      <v-form @submit.prevent="addAWhatDo" density="compact">
        
        <v-text-field v-model="title" class="font-weight-bold" clearable placeholder="Title: what thing should you be doing?"></v-text-field>

        <ul class="ml-5">
          <li v-for="(_, i) in options" :key="i">
            <v-text-field v-model="options[i]" clearable :placeholder="`option ${i+1}`"></v-text-field>
            <!-- <v-text-field v-model="options[i]" placeholder="one option, eg 'exercise options include bike, run, lift'"></v-text-field> -->
          </li>
        </ul>

        <div class="d-flex justify-space-around mb-5 pb-5">
          <v-chip @click.prevent="addOption" role="btn" variant="outlined">Add another option</v-chip>
        </div>
        
        <v-btn class="bg-green" block type="submit" :disabled="invalidWhatDo"><span class="text-white">Add this WhatDo</span></v-btn>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { addNewWhatDo } from '../../services/WhatDosServices.js';

export default {
  props: [ 'uId', 'token', 'todayActual' ], 
  data() {
    return {
      title: '',
      options: ['', ''],
    };
  },
  computed: {
    cleanedOptions() {
      let co = [];
      for (let i in this.options) {
        const option = this.options[i];
        if (option.trim().length > 0) {
          co.push(option);
        }
      }
      return co;
    },
    invalidWhatDo() {
      let r = false;
      if (this.title.trim().length < 1 || this.cleanedOptions.length < 2) {
        r = true;
      }
      return r;
    },
  },
  methods: {
    addOption() {
      this.options.push('');
    },
    async addAWhatDo() {
      console.log('starting addAWhatDo()');
      const wd = {
        title: this.title,
        options: this.cleanedOptions,
      };
      const success = await addNewWhatDo(this.uId, this.token, this.todayActual, wd);
      if (success) {
        this.$cookies.set('lastViewedTab', 'whatDos');
        this.$router.push('/my-account');
      } else {
        alert('something went wrong. try refreshing the page. plkthx');
      }
    },
  },
};
</script>

