/** DASHBOARD SERVICES */
/** DASHBOARD SERVICES */
/** DASHBOARD SERVICES */

import { addAWinToToday } from './WinsServices';

export async function compareTodaysWinsToProgramThenUpdateIfNeeded( uId, token, today, todayActual, program, wins ) { 
  console.log('starting compareTodaysWinsToProgramThenUpdateIfNeeded()');


  // look through wins & find their programId's
  let programIdsOfTodaysWins = [];
  if (wins && wins.length > 0) {
    for (const key in wins) {
      programIdsOfTodaysWins.push(wins[key].programId);
    }
  }
  
  // look through program: find missing wins & create newWin for each; get a list of wins to add to today
  let winsToAddToToday = [];
  if (program && program.length > 0) {
    for (const key in program) {
      if (!programIdsOfTodaysWins.includes(program[key].id)) {
        const newWin = {
          programId: program[key].id,
          win: program[key].win,
          stacked: false,
          dateCreated: today,
          dateUpdated: today,
        };
        // addAWinToToday(uId, today, newWin, token);
        winsToAddToToday.push(newWin);
      }
    }
  }


  // if necessary, make new API calls and mutate store
  if (winsToAddToToday.length > 0) {
    for (const key in winsToAddToToday) {
      const win = winsToAddToToday[key];
      await addAWinToToday(uId, token, today, todayActual, win);
    }
    return true;
  } else {
    return false;
  }
}