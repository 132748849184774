<template>
  <div v-if="whatDos && whatDos.length">

    <v-card v-for="whatDo in whatDos" :key="whatDo.id" :id="whatDo.id" class="my-3 bg-green-lighten-4">

      <v-card-text class="d-flex justify-space-between">
        <div>
          <h3>{{whatDo.title}}</h3>
          <ul v-for="option in whatDo.options" :key="option" class="ml-5">
            <li>{{ option }}</li>
          </ul>
        </div>
        <router-link class="edit-delete float-right text-caption text-green-darken-4" :to="`/whatDo/${whatDo.id}`">Edit / Delete</router-link>
      </v-card-text>
      
    </v-card>

  </div>
</template>

<script>
export default {
  props: [ 'whatDos' ],
};
</script>