<template>
  <div class="mt-5my-2">
    <p class="text-body-2 my-2">"What Do? 🤷‍♂️" It's a question we ask ourselves when we know we should be doing <em>something</em>, but we're not really sure which thing to do next. Eg. "I should exercise now, but should I walk, run, yoga, or lift?" Make a quick decision and get to it.</p>

    <!-- these are the user's whatDos -->
    <AccountWhatDos :whatDos="whatDos" />
    
    <!-- form to add new whatDo -->
    <div class="mt-12">
      <AddWhatDo :uId="uId" :token="token" :todayActual="todayActual" />
    </div>

  </div>
</template>
<script>
import AddWhatDo from './AddWhatDo.vue';
import AccountWhatDos from './AccountWhatDos.vue';

export default {
  props: [ 'whatDos', 'uId', 'token', 'todayActual' ],
  components: {
    AccountWhatDos,
    AddWhatDo,
  },
};
</script>