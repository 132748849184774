<template>

  <p class="my-2 text-body-2">A <strong>Win</strong> is something Your Conscience tells you to do. <em>Listen</em> and do those things today. Keep stacking those little Wins and base hits every single day, day after day. You'll feel alot better today, and your Life will improve over time.</p>
    
  <!-- this is a form to add a new program item -->
  <AddProgram :uId="uId" :token="token" :todayActual="todayActual" />

  <!-- this are the user's program, each win listed out -->
  <AccountProgram :program="program" />

</template>

<script>
import AccountProgram from './AccountProgram.vue';
import AddProgram from './AddProgram.vue';

export default {
  props: [ 'uId', 'token', 'todayActual', 'program' ],
  components: {
    AddProgram,
    AccountProgram,
  },
}
</script>


