<template>
  <div>
    
    <div v-if="todaysOneOffs && todaysOneOffs.length > 0">

      <h3 class="text-decoration-underline">One-Off Tasks for Today</h3>
      <v-card v-for="win in todaysOneOffs"
        :key="win.id"
        :id="win.id"
        class="d-flex pa-4 my-4">
        
        <v-icon
          v-if="!!win.stacked"
          class="mr-2"
          @click.prevent="stackOneOff(win, false)">
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
          v-else
          class="mr-2"
          @click.prevent="stackOneOff(win, true)"
        >
          mdi-checkbox-blank-outline
        </v-icon>

        <div class="w-100">
          <p :class="win.stacked ? 'text-decoration-line-through' : ''">{{ win.oneOff }}</p>
          <p class="d-flex flex-row-reverse"><span class="text-red text-caption" @click.prevent="deleteAOneOff(win.id)">delete</span></p>
        </div>

      </v-card>
    </div>
    
    
    <AddOneOff :uId="uId" :token="token" :today="today" :todayActual="todayActual" />
    
  </div>
</template>

<script>
import AddOneOff from './AddOneOff.vue';
import { getOneOffsFromDbase, updateAOneOff, deleteAOneOff } from '../../services/OneOffsServices.js';
export default {
  props: [ 'oneOffs', 'uId', 'token', 'today', 'todayActual' ],
  components: {
    AddOneOff,
  },
  data() { 
    return {
      todaysOneOffs: [],
    }; 
  },
  methods: {
    async stackOneOff(oneOff, stackedStatus) {
      console.log('starting stackOneOff()', oneOff, stackedStatus);
      const newOO = {
        id: oneOff.id,
        oneoff: oneOff.oneOff,
        stacked: stackedStatus,
        dateCreated: oneOff.dateCreated,
        dateUpdated: this.todayActual,
      };
      console.log('newOO', newOO);

      const didItUpdate = await updateAOneOff(this.uId, this.token, this.today, this.todayActual, newOO);
      if (didItUpdate) {
        // update front end
        console.log('oneOffs.vue > update was successful');
        // this.todaysWins = await getAllWinsForTodayFromDbase(this.uId, this.token, this.today);
        // this.$cookies.set('todaysWins', this.todaysWins);
        this.todaysOneOffs = await getOneOffsFromDbase(this.uId, this.token, this.today);
      }
    },
    async deleteAOneOff(oId) {
      console.log('starting deleteAOneOff');
      const didItUpdate = await deleteAOneOff(this.uId, this.token, this.today, oId);
      if (didItUpdate) {
        // update front end
        console.log('oneOffs.vue > delete was successful');
        // this.todaysWins = await getAllWinsForTodayFromDbase(this.uId, this.token, this.today);
        // this.$cookies.set('todaysWins', this.todaysWins);
        this.todaysOneOffs = await getOneOffsFromDbase(this.uId, this.token, this.today);
      }
    },
  },
  created() {
    this.todaysOneOffs = [...this.oneOffs];
  },
};
</script>