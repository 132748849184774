<template>
  <div>

    <p class="text-h5 mt-5 pt-5">Login</p>
    
    <v-form class="my-4">
      <v-text-field 
        label="Email" 
        variant="outlined" 
        type="email"
        v-model.trim="email"
      ></v-text-field>

      <v-text-field 
        label="Password" 
        variant="outlined" 
        type="password" 
        v-model.trim="pass"
      ></v-text-field>

      <v-btn color="green" @click.prevent="submitLogin"><span class="text-white">Login</span></v-btn>

      <router-link to="/signup" class="ml-8">Sign up instead</router-link>
    </v-form>

  </div>
</template>

<script>

import { submitLogin } from '../../services/UserServices';
import { getDates } from '../../services/DateServices';

export default {
  data() {
    return {
      
      email: '',
      pass: '',
      loginDataIsValid: true,
      
    };
  },
  computed: {
    uId() {
      return this.$store.getters['uId'];
    },
    token() {
      return this.$store.getters['token'];
    }
  },
  methods: {
    async submitLogin() {
      // "validation"
      if (this.email.length > 0 && this.pass.length >= 6) {
        try {
          // log in
          const user = await submitLogin(this.email, this.pass);
          const ta = getDates(null);
          const d = ta.todayActual;
          // set user info
          this.$cookies.set('uId', user.uId, '3600s');
          this.$cookies.set('token', user.token, '3600s');
          this.$cookies.set('todaysDate', d);
          this.$store.dispatch('isAuthenticated', true);
          // auto-logout in 1 hr
          setTimeout(()=> {
            this.$cookies.remove('uId');
            this.$cookies.remove('token');
            this.$cookies.remove('todaysDate');
            this.$store.dispatch('isAuthenticated', false);
          }, 3600000);
          // auto-redirect to dashboard
          this.$router.push('/dash');
        } catch(err) {
          console.log('Error', err);
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
