<template>
  <div id="my-account" class="px-4">

    <!-- page header -->
    <div class="d-flex justify-space-between my-3 align-center">
      <h1 class="text-h5 my-3 font-weight-bold">My Account</h1>
      <v-btn v-if="view == 'reasons'" @click.prevent="hardReset(['reasons'])" size="x-small" color="grey">Refresh</v-btn>
      <v-btn v-else-if="view == 'program'" @click.prevent="hardReset(['program'])" size="x-small" color="grey">Refresh</v-btn>
      <v-btn v-if="view == 'whatDos'" @click.prevent="hardReset(['whatDos'])" size="x-small" color="grey">Refresh</v-btn>
    </div>

    <!-- in-page menu -->
    <v-tabs grow hide-slider>
      <v-tab :class="{ 'active': view == 'reasons' }" @click="setView('reasons')">
        Remember
      </v-tab>

      <v-tab :class="{ 'active': view == 'program' }" @click="setView('program')">
        Program
      </v-tab>

      <v-tab :class="{ 'active': view == 'whatDos' }" @click="setView('whatDos')">
        WhatDo
      </v-tab>
    </v-tabs>


    <!-- <pre>reasons: {{ reasons }}</pre> -->
    <!-- <pre>program: {{ program }}</pre> -->

    <!-- where users can adjust their reasons/reminders -->
    <div v-if="loading" class="text-center mt-5 pt-5">
      <v-progress-circular :size="100" color="amber" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <section v-if="view == 'reasons' && reasons">
        <MyReasons :uId="uId" :token="token" :todayActual="todayActual" :reasons="reasons" />
      </section>
      
      <!-- this is the program, where users can adjust their daily wins -->
      <section v-if="view == 'program' && program">
        <MyProgram :uId="uId" :token="token" :todayActual="todayActual" :program="program" />
      </section>
      
      <!-- whatDos -->
      <section v-if="view == 'whatDos' && whatDos">
        <MyWhatDos :whatDos="whatDos" :uId="uId" :token="token" :todayActual="todayActual" />
      </section>
    </div>    
  </div>
</template>

<script>
import MyReasons from './MyReasons.vue';
import MyProgram from './MyProgram.vue';
import MyWhatDos from './MyWhatDos.vue';

import { getDates } from '../../services/DateServices';
import { getAllReasonsFromDbase } from '../../services/ReasonsServices';
import { getProgramFromDbase } from '../../services/ProgramServices';
import { getWhatDosFromDbase } from '../../services/WhatDosServices';


export default {
  components: {
    MyReasons,
    MyProgram,
    MyWhatDos,
  },
  data() {
    return {
      loading: true,

      uId: null,
      token: null,
      todayActual: null,
      view: 'reasons',

      reasons: null,
      program: null,
      whatDos: null,
    };
  },
  methods: {
    setView(view) {
      this.view = view;
      this.$cookies.set('lastViewedTab', view);
    },
    async hardReset(listOfThingsToUpdate) {
      this.loading = true;

      if (listOfThingsToUpdate.includes('reasons')) {
        this.reasons = await getAllReasonsFromDbase(this.uId, this.token); // get reasons
      }
      if (listOfThingsToUpdate.includes('program')) {
        this.program = await getProgramFromDbase(this.uId, this.token); // get program
      }
      if (listOfThingsToUpdate.includes('whatDos')) {
        this.whatDos = await getWhatDosFromDbase(this.uId, this.token); // get whatDos
      }

      this.loading = false;
    },
  },
  async created() {

    // if user auth has expired, then send to homepage to log in again
    if (!this.$store.getters['isAuthenticated'] || !this.$cookies.isKey('uId') || !this.$cookies.isKey('token')) {
      this.$cookies.remove('uId');
      this.$cookies.remove('token');
      this.$router.push('/');
    } else {
      this.uId = this.$cookies.get('uId');
      this.token = this.$cookies.get('token');
    }



    // get todayActual
    const ta = getDates(null);
    this.todayActual = ta.todayActual;



    // set the last viewed tab (if any)
    if (this.$cookies.isKey('lastViewedTab')) {
      this.setView(this.$cookies.get('lastViewedTab'));
    }

    

    // get reasons, program, and whatDos
    await this.hardReset(['reasons', 'program', 'whatDos']);
    console.log('whatDos', this.whatDos);


  }
}
</script>


<style lang="scss" scoped>
button.v-tab {
  border: 2px solid black;
  border-radius: 20px;
}
button.v-tab.active {
  background-color: black;
  color: gold;
  font-weight: bold;
  text-decoration: underline;
}
</style>
