<template>
  <div>

    <p class="my-2 text-body-2">Always keep Your Vision in mind. Feel it, believe it, that you're already living it, all day, every day, as many times a day as you can.</p>
    
    <!-- this is a form to add a new reason -->
    <AddReason :uId="uId" :token="token" :todayActual="todayActual" />
    
    <!-- these are the user's reasons, listed out -->
    <AccountReasons :uId="uId" :token="token" :todayActual="todayActual" :reasons="reasons" />
    
  </div>
</template>

<script>
import AddReason from './AddReason.vue';
import AccountReasons from './AccountReasons.vue';

export default {
  props: [ 'uId', 'token', 'todayActual', 'reasons' ],
  components: {
    AddReason,
    AccountReasons,
  },
};
</script>

