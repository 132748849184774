/** USER SERVICES */
/** USER SERVICES */
/** USER SERVICES */

const FIREBASE_KEY = process.env.VUE_APP_FIREBASE_API_KEY;




export async function submitLogin(email, pass) {
  // console.log('starting UserServices > submitLogin', email, pass);

  // try to log in
  try {
    // (call the auth server)
    const response = await fetch(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${FIREBASE_KEY}`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password: pass,
        returnSecureToken: true,
      }),
    });
    const responseData = await response.json();
    // if no user
    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(response.message || 'Failed to authenticate');
      throw error;
    // if user
    } else {
      const uId = responseData.localId;
      const token = responseData.idToken;
      return { uId, token };
    }
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}



// https://firebase.google.com/docs/reference/rest/auth#section-sign-in-email-password
// Sign in anonymously
// You can sign in a user anonymously by issuing an HTTP POST request to the Auth signupNewUser endpoint.
// Method: POST
// https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]






/**
 * used in /dash/DashBoard
 * TBD: used in /account/...
 */
export async function getAllReasonsFromDbase(uId, token) {
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/reasons/${uId}.json?auth=${token}`);
  const responseData = await response.json();
  
  // error handling
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to add a win to today');
    throw error;
  }

  // manipulate & return data
  const reasons = [];
  for (const key in responseData) {
    const reasonObj = {
      id: key,
      reason: responseData[key].reason,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    reasons.push(reasonObj);
  }
  return reasons;
}



// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
export async function getJournalForTodayFromDbase(uId, token, today) {
  const journal = 'lol journal for today from dbase';
  console.log('getJournalForTodayFromDbase', uId, token, today);
  return journal;
}
export async function getAllWinsForTodayFromDbase(uId, token, today) {
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/wins/${uId}/${today}.json?auth=${token}`);
  const responseData = await response.json();
  // err handling
  if(!response.ok) {
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to get today\'s wins from dbase');
    throw error;
  }
  const wins = [];
  for (const key in responseData) {
    const winObj = {
      id: key,
      win: responseData[key].win,
      stacked: responseData[key].stacked,
      programId: responseData[key].programId,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    wins.push(winObj);
  }
  return wins;
}
export async function getProgramFromDbase(uId, token) {
  // console.log('starting getProgramFromDbase()');
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/program/${uId}.json?auth=${token}`);
  const responseData = await response.json();
  // "err handling" - needs to be made real
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to add a win to today');
    throw error;
  }
  // manipulate & return data
  let program = [];
  for (const key in responseData) {
    const winObj = {
      id: key,
      win: responseData[key].win,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    program.push(winObj);
  }
  // store.dispatch('wins/setProgram', {program});
  // console.log('program', program);
  return program;
}
async function addAWinToTodaysWins(uId, token, today, win) {

  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/wins/${uId}/${today}.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(win),
  });
  // const responseData = await response.json();
  
  if (!response.ok) {
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to add a win to today');
    throw error;
  }
  
}
export async function compareTodaysWinsToProgramThenUpdateIfNeeded( uId, token, today, program, wins ) { 
  // console.log('starting compareTodaysWinsToProgramThenUpdateIfNeeded()');
  let programIdsOfTodaysWins = [];
  let winsToAddToToday = [];
  // look through wins & find their programId's
  if (wins && wins.length > 0) {
    for (const key in wins) {
      programIdsOfTodaysWins.push(wins[key].programId);
    }
  }
  // console.log('got programIdsOfTodaysWins:', programIdsOfTodaysWins);
  // look through program: find missing wins & create newWins
  if (program && program.length > 0) {
    for (const key in program) {
      if (!programIdsOfTodaysWins.includes(program[key].id)) {
        const newWin = {
          programId: program[key].id,
          win: program[key].win,
          stacked: false,
          dateCreated: today,
          dateUpdated: today,
        };
        // addAWinToToday(uId, today, newWin, token);
        winsToAddToToday.push(newWin);
      }
    }
  }
  // if necessary, make new API calls and mutate store
  if (winsToAddToToday.length > 0) {
    for (const key in winsToAddToToday) {
      const win = winsToAddToToday[key];
      await addAWinToTodaysWins(uId, token, today, win);
    }
    // console.log('winsToAddToToday:', winsToAddToToday);
    // wins = await getAllWinsForTodayFromDbase(uId, today, token);
    return true;
  } else {
    return false;
  }
}
// UPDATE (ONE WIN)
// export async function updateOneWin(uId, token, today, win) {
export async function updateOneWin(uId, token, today, win, stackedStatus) {
  // uId, today, 
  // const dateOfWin = data.dateOfWin;
  const winId = win.id;
  const updatedWin = {
    win: win.win,
    stacked: stackedStatus,
    programId: win.programId,
    dateCreated: win.dateCreated,
    dateUpdated: today,
  };
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/wins/${uId}/${today}/${winId}.json?auth=${token}`, {
    method: 'PUT',
    body: JSON.stringify(updatedWin)
  });
  // const responseData = await response.json();
  if (!response.ok) {
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to update a Win');
    throw error;
  } else {
    return true;
  }


  // mutate the store
  // let wins = await getAllWinsForTodayFromDbase(uId, today, token);
  // context.commit('setWins', wins);
}

