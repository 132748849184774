<template>
  <div class="px-4">
    
    <div class="text-h5 my-3 font-weight-bold">Edit Program</div>

    <div v-if="!!win">

      <v-form @submit.prevent="updateOneProgram" density="compact" class="mt-8 mb-14">
        <v-textarea
          rows="3"
          auto-grow
          bg-color="white"
          variant="outlined"
          v-model.trim="win"
        ></v-textarea>
        <p class="text-subtitle-2">Created on: {{ dateCreated }}</p>
        <p class="text-subtitle-2 mb-4">Last updated on: {{ dateUpdated }}</p>
        <v-btn type="submit" block class="bg-green text-white">Update Program</v-btn>
      </v-form>

      <div class="d-flex justify-space-between">
        <v-btn @click.prevent="cancel" class="bg-grey">Cancel</v-btn>
        <v-btn @click.prevent="deleteAProgram" class="bg-red"><span class="text-white">Delete this Daily Win</span></v-btn>
      </div>

    </div>


  </div>
</template>

<script>
import { getDates } from '../../services/DateServices';
import { getOneProgramFromDbase, updateOneProgramItem, deleteOneProgramItem } from '../../services/ProgramServices';

export default {
  props: ['id'],
  data() {
    return {
      uId: null,
      token: null,
      todayActual: null,

      win: null,
      dateCreated: null,
      dateUpdated: null,
    };
  },
  methods: {
    async getTheProgram() {

      const p = await getOneProgramFromDbase(this.uId, this.token, this.id);
      if (p) {
        this.win = p.win;
        this.dateCreated = p.dateCreated;
        this.dateUpdated = p.dateUpdated;
      }

    },
    async updateOneProgram() {

      const success = await updateOneProgramItem(this.uId, this.token, this.todayActual, this.id, this.win);
      console.log('was updateOneProgramItem() successful?', success);

      if (success) {
        this.$router.push('/my-account');
      }


    },
    async deleteAProgram() {

      const success = await deleteOneProgramItem(this.uId, this.token, this.id);
      if (success) {
        this.$router.push('/my-account');
      }

    },
    cancel() {
      this.$router.push('/my-account');
    },
  },
  async created() {

    if (!this.$store.getters['isAuthenticated'] || !this.$cookies.isKey('uId') || !this.$cookies.isKey('token')) {
      this.$cookies.remove('uId');
      this.$cookies.remove('token');
      this.$router.push('/');
    }

    this.$cookies.set('lastViewedTab', 'program');

    this.uId = this.$cookies.get('uId');
    this.token = this.$cookies.get('token');
    const ta = getDates();
    this.todayActual = ta.todayActual;

    await this.getTheProgram();
  },
}
</script>


<style scoped>
label {
  font-size: 12px;
}
textarea {
  width: 100%;
}
.flex {
  display: flex;
  justify-content: space-between;
}
input[type=submit] {
  padding: 8px;
  background-color: green;
  color: white;
  /* text-shadow: 1px 1px; */
  font-weight: bold;
  border-radius: 4px;
}
button {
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
}
.cancel {
  background-color: lightgray;
  color: darkslategray;
}
.delete {
  background-color: lightcoral;
  color: white;
}
</style>
