<template>
  <div id="page-faq" class="pa-3">
    <h1 id="h1-faqs">FAQs</h1>

    <!-- "send us a note" -->
    <div class="pt-3 pb-5">
      <p class="text-h6">Don't see your question answered below? <a href="#contact-form">Send us a note.</a></p>
    </div>

    <!-- faq's -->
    <div id="faqs" class="my-3">
      <v-expansion-panels>
        <v-expansion-panel v-for="faq in faqs" :key="faq.title" :title=faq.title :text=faq.text></v-expansion-panel>
      </v-expansion-panels>
    </div>


    <!-- contact form -->
    <div id="contact-form">
      <p>-- contact form coming soon --</p>
      <p><a href="#h1-faqs">back to top</a></p>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          title: 'what is a program? what is a win?',
          text: `your daily program is how you live each day. how consistently you stick to this list of activites every day. and these activities are things that make you feel proud, make you feel like your true self. each activity you complete counts as a "win". you stack your wins high every day, and you're guaranteed to feel better about yourself and about your life. fundamental daily acts of self-investment include: eating right (food log), exercising, talking/spending time with loved ones, or learning/using a new skill. hobbies or acts or charity. whatever`
        },
        {
          title: 'what happened to my account? where is the old version of stackinwins?',
          text: `if you used the previous version of stackinwins, then you were using version 1. we are currently on version 2 of stackinwins. we are working on porting over user data from v1 to v2 (or deciding if that's even necessary). please feel free to create a new account and continue using the new and improved stackinwins.com. thank you for trying to be a better person!`
        },
        {
          title: 'what is the wachamacallit?',
          text: 'yup'
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#contact-form {
  margin-top: 800px;
}
.v-expansion-panel-title__overlay {
  font-weight: bold !important;
}
</style>