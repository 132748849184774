/** DATE SERVICES */
/** DATE SERVICES */
/** DATE SERVICES */


// this is the newest version, as of 2023-02-02
export function getDates(date) {
  // define return object
  let d = {
    todayActual: null,
    today: null,
    todaysDateDisplay: null,
    dayOfWeek: null,
  }

  // adjust for timezone
  let ta = new Date();
  let timezoneOffset = ta.getTimezoneOffset() / 24/60;
  ta.setDate(ta.getDate() + timezoneOffset);


  // today actual
  let todayActual = ta.toLocaleDateString().split('/');
  if (parseInt(todayActual[0]) < 10) {
    todayActual[0] = '0' + parseInt(todayActual[0]);
  }
  if (parseInt(todayActual[1]) < 10) {
    todayActual[1] = '0' + parseInt(todayActual[1]);
  }
  todayActual.splice(0, 0, todayActual.pop());
  todayActual = todayActual.join('-');
  d.todayActual = todayActual;

  
  // today, can be set by user via url route
  // todaysDateDisplay
  if( date ) {
    d.today = date;
    let tdd = date.split('-');
    tdd.splice(2, 0, tdd.shift());
    tdd = `${parseInt(tdd[0])}/${parseInt(tdd[1])}/${tdd[2]}`;
    d.todaysDateDisplay = tdd;
  } else {
    let t = ta.toLocaleDateString().split('/');
    if (parseInt(t[0]) < 10) {
      t[0] = '0' + parseInt(t[0]);
    }
    if (parseInt(t[1]) < 10) {
      t[1] = '0' + parseInt(t[1]);
    }
    t.splice(0, 0, t.pop());
    t = t.join('-');
    d.today = t;
    d.todaysDateDisplay = ta.toLocaleDateString();
  }

  
  // day of week
  let dow = new Date(d.todaysDateDisplay);
  dow = dow.getDay();
  d.dayOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'][dow];


  return d;
}

// deprecated
export function getTodayActual() {
  let d = new Date().toLocaleDateString();
  d = getTimeZoneCorrectedDateObject(d).toLocaleDateString();
  d = d.split('/');
  d.splice(0, 0, d.pop());
  if (parseInt(d[1]) < 10) {
    d[1] = '0' + d[1];
  }
  if (parseInt(d[2]) < 10) {
    d[2] = '0' + d[1];
  }
  d = d.join('-');
  return d;
}
// deprecated - function addZeroesToDate(date) {}
export function getDatesForDashboard(todaysDateURLParam) {
  const d = new Date();

  let todayActual = d.toLocaleDateString();
  todayActual.split('/');
  todayActual.splice(0, 0, todayActual.pop());
  if (parseInt(todayActual[1]) < 10) {
    todayActual[1] = '0' + todayActual[1];
  }
  if (parseInt(todayActual[2]) < 10) {
    todayActual[2] = '0' + todayActual[2];
  }
  todayActual = todayActual.join('-');

  let today = '';
  let todaysDateDisplay = '';
  let dayOfWeek = '';
  if (todaysDateURLParam) {
    today = todaysDateURLParam;
    // todaysDateDisplay = 
  } else {
    // today;
    // todaysDateDisplay = ;
    // dayOfWeek
  }

  return {
    todayActual,
    today,
    dayOfWeek,
    todaysDateDisplay
  };
  
}
// deprecated
export function getDashDatePickerValues(todaysDate) {
  // todaysDate is the url param (or todayActual), which is formatted yyyy-mm-dd
  let td = todaysDate.split('-');
  td[1] = parseInt(td[1]);
  td[2] = parseInt(td[2]);
  const displayDate = [ td[1], td[2], td[0] ].join('/');
  let dOW = new Date(displayDate).getDay();
  const dayOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'][dOW];
  return {
    date: todaysDate,
    displayDate,
    dayOfWeek,
  };
}
// deprecated
export function getYMDFromNow(dateNow) {
  const d = new Date(dateNow).getDay();
  const monthRaw = dateNow.split('/')[0];
  const month =  monthRaw < 10 ? '0' + dateNow.split('/')[0] : dateNow.split('/')[0];
  const dayRaw = dateNow.split('/')[1];
  const day = dayRaw < 10 ? '0' + dateNow.split('/')[1] : dateNow.split('/')[1];
  const year = dateNow.split('/')[2];
  return {
    date: `${year}-${month}-${day}`,
    displayDate: `${monthRaw}/${dayRaw}/${year}`,
    dayOfWeek: getDayOfWeek(d)
  }; 
}
// deprecated
function getDayOfWeek(dayIndex) {
  return ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'][dayIndex];
}
// deprecated
export function getTimeZoneCorrectedDateObject(date) {
  // the date param should be formatted yyyy-mm-dd
  const d = date.split('-');
  let returnDateObj = new Date(`${parseInt(d[1])}/${parseInt(d[2])}/${d[0]}, 00:00:01`);
  const offset = returnDateObj.getTimezoneOffset() / 24/60; // offset needs to be in the multiples of days bc getDate() lets you shift the date by multiples of days.
  // correct for timezone
  returnDateObj.setDate(returnDateObj.getDate() + offset);
  // return corrected time
  return returnDateObj;
}

// date navigators: yesterday, tomorrow
export function getYMDYesterday(today) {
  console.clear();

  // calculate yesterday
  let t = getTimeZoneCorrectedDateObject(today);
  t.setDate(t.getDate() - 1);

  // format return string (yesterdaysDate)
  let y = t.toLocaleDateString().split('/');
  if (parseInt(y[0]) < 10) {
    y[0] = '0' + y[0];
  }
  if (parseInt(y[1]) < 10) {
    y[1] = '0' + y[1];
  }
  // move the last item (year) to the 0 index
  y.splice(0, 0, y.pop());
  const yD = y.join('-');
  return yD;
}
export function getYMDTomorrow(today) {
  console.clear();
  console.log('starting getYMDTomorrow()', today);

  // calculate tomorrow
  let t = getTimeZoneCorrectedDateObject(today);
  t.setDate(t.getDate() + 1);

  // formate return string (tomorrowDate)
  let tom = t.toLocaleDateString().split('/');
  if (parseInt(tom[0]) < 10) {
    tom[0] = '0' + tom[0];
  }
  if (parseInt(tom[1]) < 10) {
    tom[1] = '0' + tom[1];
  }
  // move the last item (year) to the 0 index
  tom.splice(0,0, tom.pop());
  const td = tom.join('-');
  return td;
}



