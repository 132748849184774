/** PROGRAM SERVICES */
/** PROGRAM SERVICES */
/** PROGRAM SERVICES */

// CREATE: ADD ITEM TO PROGRAM - return true if create is successful, or error.
export async function addNewItemToProgram(uId, token, todayActual, program) {
  console.log('starting addNewItemToProgram()');
  // build the data
  const newItem = {
    win: program,
    dateCreated: todayActual,
    dateUpdated: todayActual,
  };
  // build the REST API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/program.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(newItem)
  });
  // response
  // const responseData = await response.json();
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to add a new item to Program');
    throw error;
  } else {
    return true;
  }
}


// READ ALL PROGRAM ITEMS - return array of program items, or empty array.
export async function getProgramFromDbase(uId, token) {
  console.log('starting getProgramFromDbase()');
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/program.json?auth=${token}`);
  const responseData = await response.json();
  // response
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to get user\'s program');
    throw error;
  }
  // return data
  let program = [];
  if (responseData) {
    for (const key in responseData) {
      const w = responseData[key];
      const winObj = {
        id: key,
        win: w.win,
        dateCreated: w.dateCreated,
        dateUpdated: w.dateUpdated,
      };
      program.push(winObj);
    }
  }
  return program;
}



// READ ONE PROGRAM ITEM - return program item object, or false.
export async function getOneProgramFromDbase(uId, token, pId) {
  console.log('starting getOneProgramFromDbase()');
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/program/${pId}.json?auth=${token}`);
  const responseData = await response.json();
  // response
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to get one program');
    throw error;
  }
  // return data
  let program = false;
  if (responseData) {
    program = {
      id: pId,
      win: responseData.win,
      dateCreated: responseData.dateCreated,
      dateUpdated: responseData.dateUpdated,
    };
  }
  return program;
}





// UPDATE A PROGRAM ITEM - return true if update is successful, or error.
export async function updateOneProgramItem(uId, token, todayActual, pId, program) {
  console.log('starting updateOneProgramItem()');
  // API call
  const updatedProgram = {
    win: program,
    dateUpdated: todayActual,
  };
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/program/${pId}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify(updatedProgram)
  });
  // response
  const responseData = await response.json();
  if (!response.ok) {
    console.log('response is not ok', responseData);
    const error = new Error(response.message || 'Failed to update a Program item');
    throw error;
  } else {
    return true;
  }
}


// DELETE A PROGRAM - return true if delete is successful, or error.
export async function deleteOneProgramItem(uId, token, pId ) {
  console.log('starting deleteOneProgram()');
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/program/${pId}.json?auth=${token}`, {
    method: 'DELETE',
  });
  // response
  const responseData = await response.json();
  if (!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to delete a Program item');
    throw error;
  } else {
    return true;
  }
}


