<template>
  <div class="px-4">
    <div class="text-h5 my-3 font-weight-bold">Edit Reason</div>

    <div v-if="!!reason">
      <v-form
        @submit.prevent="updateReason" 
        density="compact"
        class="mt-8 mb-14"
      >
        <v-textarea
          rows="3"
          auto-grow
          bg-color="white"
          variant="outlined"
          v-model.trim="reason"
        ></v-textarea>
        <div class="text-subtitle-2">Created on: {{ dateCreated }}</div>
        <div class="text-subtitle-2 mb-4">Last updated on: {{ dateUpdated }}</div>
        <v-btn
          type="submit"
          block
          class="bg-green"
        ><span class="text-white">Update Reason</span></v-btn>
      </v-form>
      <div class="d-flex justify-space-between">
        <v-btn @click.prevent="cancel" class="bg-grey">Cancel</v-btn>
        <v-btn @click.prevent="deleteReason" class="bg-red"><span class="text-white">Delete Reason</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { getDates } from '../../services/DateServices';
import { getOneReasonFromDbase, updateReason, deleteReason } from '../../services/ReasonsServices';
export default {
  props: ['id'],
  data() {
    return {
      uId: null,
      token: null,
      todayActual: null,

      reason: null,
      dateCreated: null,
      dateUpdated: null,
    };
  },
  methods: {
    async getTheReason() {
      const r = await getOneReasonFromDbase(this.uId, this.token, this.id);
      this.reason = r.reason;
      this.dateCreated = r.dateCreated;
      this.dateUpdated = r.dateUpdated;
      // console.log('this is the reason', r);
    },
    async updateReason() {
      console.log('updateReason');
      const success = await updateReason(this.uId, this.token, this.todayActual, this.id, this.reason);
      if (success) {
        this.$router.push('/my-account');
      }      
    },
    async deleteReason() {
      const success = await deleteReason(this.uId, this.token, this.id);
      if (success) {
        this.$router.push('/my-account');
      }
    },
    cancel() {
      this.$router.push('/my-account');
    },
  },
  created() {

    if (!this.$store.getters['isAuthenticated'] || !this.$cookies.isKey('uId') || !this.$cookies.isKey('token')) {
      this.$cookies.remove('uId');
      this.$cookies.remove('token');
      this.$router.push('/');
    }

    this.$cookies.set('lastViewedTab', 'reasons');

    this.uId = this.$cookies.get('uId');
    this.token = this.$cookies.get('token');
    const ta = getDates();
    this.todayActual = ta.todayActual;

    this.getTheReason();

  },
};
</script>

