<template>
  <v-card variant="outlined" density="compact" class="bg-teal mt-3 mb-5">


    <v-card-text>
      <v-form @submit.prevent="addAWinToProgram" density="compact">
        <v-textarea
          clearable
          auto-grow
          density="compact"
          bg-color="white"
          v-model.trim="newWin"
        ></v-textarea>
        <v-btn class="bg-green" block type="submit" :disabled="invalidWin"><span class="text-white">Add new Win to Daily Program</span></v-btn>
      </v-form>
    </v-card-text>


  </v-card>
</template>

<script>
import { addNewItemToProgram } from '../../services/ProgramServices';

export default {
  props: [ 'uId', 'token', 'todayActual' ],
  data() {
    return {
      newWin: 'STACK Wins every day.',
    };
  },
  computed: {
    invalidWin() {
      return this.newWin.length > 0 ? false : true;
    },
  },
  methods: {
    async addAWinToProgram() {
      console.log('starting addAWinToProgram()');

      const success = await addNewItemToProgram(this.uId, this.token, this.todayActual, this.newWin);

      if (success) {
        console.log('add win to program, success');
        this.newWin = 'STACK Wins every day.';
      }


      // const data = {
      //   win: this.newWin,
      //   dateCreated: this.today,
      //   dateUpdated: this.today,
      // };
      // console.log('starting this.$store.dispatch(wins/addNewWinToProgram', data);
      // this.$store.dispatch('wins/addNewWinToProgram', data);
      // this.newWin = '';
    }
  }
}
</script>


