/** WHATDO's SERVICES */
/** WHATDO's SERVICES */
/** WHATDO's SERVICES */

// CREATE A WHATDO - return true if create is successful, or error.
export async function addNewWhatDo(uId, token, todayActual, whatDo) {
  console.log('starting addNewWhatDo()');
  // console.log('starting addNewWhatDo', uId, token, todayActual, whatDo);
  // build the data
  const wd = {
    title: whatDo.title,
    options: whatDo.options,
    dateCreated: todayActual,
    dateUpdated: todayActual,
  };
  // build the REST API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/whatdos.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(wd),
  });
  const responseData = await response.json();
  // "err handling" - needs to be made real
  if(!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to add a new whatDo options');
    throw error;
  } else {
    return true;
  }
}


// READ ALL WHATDO ITEMS - return array of whatDo items, or empty array.
export async function getWhatDosFromDbase(uId, token) {
  console.log('starting getWhatDosFromDbase()');
  // console.log('starting getWhatDosFromDbase()', uId, token);
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/whatdos.json?auth=${token}`);
  const responseData = await response.json();
  // response
  if(!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to add a win to today');
    throw error;
  }
  // return data
  let whatDos = [];
  for (const key in responseData) {
    const wd = responseData[key];
    const winObj = {
      id: key,
      title: wd.title,
      options: wd.options,
      dateCreated: wd.dateCreated,
      dateUpdated: wd.dateUpdated,
    };
    whatDos.push(winObj);
  }
  return whatDos;
}


// READ ONE WHATDO - returns one whatDo object, or false.
export async function getOneWhatDoFromDbase(uId, token, wdId) {
  console.log('starting getOneWhatDoFromDbase()');
  
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/whatdos/${wdId}.json?auth=${token}`);
  if (!response.ok) {
    const error = new Error(response.message || 'Failed to get one whatDo');
    throw error;
  }

  const r = await response.json();

  if (r) {
    const whatDo = {
      title: r.title,
      options: r.options,
      dateCreated: r.dateCreated,    
      dateUpdated: r.dateUpdated,
    };
    return whatDo;
  } else {
    return false;
  }
}


// UPDATE A WHATDO's OPTIONS - return true if update is successful, or error.
export async function updateOneWhatDo(uId, token, todayActual, whatDo) {
  console.log('starting updateOneWhatDo()');
  // console.log('starting updateOneWhatDo()', uId, token, todayActual, whatDo);
  // build the data
  const wId = whatDo.id;
  const updatedWhatDo = {
    title: whatDo.title,
    options: whatDo.options,
    dateUpdated: todayActual,
  };
  // response
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/whatdos/${wId}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify(updatedWhatDo)
  });
  const responseData = await response.json();
  if (!response.ok) {
    console.log(responseData);
    const error = new Error(response.message || 'Failed to update a whatDo');
    throw error;
  } else {
    return true;
  }
}


// DELETE A WHATDO - return true if delete is successful, or error.
export async function deleteOneWhatDo(uId, token, wId ) {
  console.log('starting deleteOneWhatDo()');
  // console.log('starting deleteOneWhatDo()', uId, token, wId);
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/whatdos/${wId}.json?auth=${token}`, {
    method: 'DELETE',
  });
  // response
  const responseData = await response.json();
  if (!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to delete a whatDo');
    throw error;
  } else {
    return true;
  }
}


