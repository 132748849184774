import { createApp } from 'vue';

import router from './router.js';
import store from './store/index.js';
import vuetify from './plugins/vuetify.js';
import VueCookies from 'vue3-cookies';
// import VueHead from 'vue-head';
import { loadFonts } from './plugins/webfontloader.js';
import App from './App.vue';
// import CKEditor from '@ckeditor/ckeditor5-vue';

loadFonts();

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(VueCookies, {
  expireTimes: '1d',
  path: '/',
});
// app.use(VueHead);
// app.use(CKEditor);
// app.config.compilerOptions.isCustomElement = (tag) => {
//   return tag.startsWith('tinymce-');
// };

app.mount('#app');
