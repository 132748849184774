<template>
  <div class="mt-5">
    
    <div v-if="srcWhatDos && srcWhatDos.length > 0">

      <h3 class="text-decoration-underline">WhatDos</h3>

      <v-card v-for="whatDo in srcWhatDos" :key="whatDo.id" class="pa-4 my-4">
        <!-- <h3>{{ whatDo.title }}</h3> -->
        <v-card-title class="text-decoration-underline">{{ whatDo.title }}</v-card-title>
        <ul class="ml-5">
          <li v-for="option in whatDo.options" :key="option">{{ option }}</li>
        </ul>
        <div class="d-flex flex-row-reverse">
          <a :href="whatDo.src" target="_blank"><v-chip color="green-darken-4" size="small">Do this whatDo</v-chip></a>
        </div>
      </v-card>

    </div>

    <!-- <iframe id="my-iframe" src="https://missingdice.com/spin-the-wheel/?values=foam%20roll+100%20squats+100%20push-ups+100%20pulls+100%20bridges+100%20leg%20raises" frameborder="0" scrolling="no" width="98%" height="1000px"></iframe> -->


  </div>
</template>

<script>
export default {
  props: [ 'whatDos' ],
  data() {
    return {
      srcWhatDos: [],
    };
  },
  created() {
    let wd = [];
    for (let i in this.whatDos) {
      const w = this.whatDos[i];
      let src = 'https://missingdice.com/spin-the-wheel/?values=';
      for (let k in w.options) {
        if (k > 0) {
          src += '+';
        }
        src += encodeURI(w.options[k]);
      }
      wd.push({
        title: w.title,
        options: [...w.options],
        src,
      });
    }
    this.srcWhatDos = wd;
  },
};
</script>