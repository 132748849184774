<template>
  <p class="text-body-2 my-3">Take several, multiple moments throughout the day to really enjoy thinking about each aspect of your dream life.</p>
  <p class="text-body-2 mb-3"><strong>SEE it vividly</strong>, <strong>FEEL it viscerally</strong>, as if you already have your dream life, right down to the finest detail. This will not only put you in a great mood and the proper state of mind, but it will help your mind unlock the steps to GET your dream life.</p>

  <div v-if="reasons && reasons.length">
    <v-card
      v-for="reason in reasons"
      :key="reason.id"
      :id="reason.id"
      variant="outlined"
      class="my-5"
    >
      <v-card-title>
        <div class="text-body-1 text-wrap">{{ reason.reason }}</div>
      </v-card-title>
    </v-card>
  </div>

  <div v-else class="text-body-2 mt-5">
    <p class="mb-1"><strong>You have yet to add descriptions of your dream life, of the person you want to be.</strong></p> <p class="mb-3"><strong>Please find below some prompts for common aspects of a good life. Define YOUR Good Life for yourself.</strong> It can happen for you, and you can make it happen. Keep spending time thinking about it, visualizing it, feeling it.</p>
    <router-link to="/my-account" class="font-weight-bold">Add some now ></router-link>
    <ul class="pl-3 mt-3 mb-5">
      <li>visualize driving your car. sit in the seat. put your hands at 10 and 2. adjust the rear view. look at your dash. shift into gear. open the windows. hear the music playing. drive down your street.</li>
      <li>visualize your physique. what do you want your body to look like? do you want more muscle? do you want to lose fat? do you want to be stronger, more flexible? do you want to look good naked?</li>
      <li>visualize how you look. what's your favorite outfit(s)? however you want to look is ok. but refine it, elevate it, invest in yourself so you can be proud of how you look.</li>
      <li>visualize your dream wife. what does she look like? what does she sound like? smell like? how's her personality? what philosophies, morals, values do you agree on? what dreams do you share?</li>
      <li>visualize your relationship with your parents [if they're still in the picture]. what kind of relationship do you want with them? how do you want to feel about them, and how do they want to feel about you? it's possible; for now just imagine how you would feel if you had that ideal relationship. bask in it.</li>
      <li>visualize the friends you want to have? are they the same homies from back when, or are they new friends? what's your crew like, how does your team roll together, what are y'all about?</li>
      <li>how's your financials? how much money in the bank do you have in your dream life? how much do you need to provide for yourself, your family, your children, their children? how much do you want?</li>
    </ul>
    <router-link to="/my-account" class="font-weight-bold">Add some now ></router-link>
  </div>
</template>

<script>
export default {
  props: [ 'reasons' ],
};
</script>

<style lang="scss" scoped>
a { 
  color: black; 
  text-decoration: none;
}
</style>