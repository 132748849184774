/** ONE-OFF's SERVICES */
/** ONE-OFF's SERVICES */
/** ONE-OFF's SERVICES */



// CREATE A ONE-OFF - return true if create is successful, or error.
export async function addNewOneOffToToday(uId, token, today, todayActual, oneOff) {
  console.log('starting addNewOneoffToToday()');
  // console.log('starting addNewOneoffToToday', uId, token, todayActual, oneOff);
  // build the data
  const newOneOff = {
    oneoff: oneOff,
    stacked: false,
    dateCreated: todayActual,
    dateUpdated: todayActual,
  };
  // build the REST API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/oneoffs.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(newOneOff)
  });
  // response
  const responseData = await response.json();
  if(!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to add a new oneOff');
    throw error;
  } else {
    return true;
  }
}



// READ ALL ONE-OFF'S - return array of oneOffs, or empty array.
export async function getOneOffsFromDbase(uId, token, today) {
  console.log('starting getOneOffsFromDbase()');
  // console.log('starting getOneOffsFromDbase()', uId, token, today);
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/oneoffs.json?auth=${token}`);
  // response
  const responseData = await response.json();
  if(!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to get all oneOff\'s for today');
    throw error;
  }
  // return data
  let oneOffs = [];
  if (responseData) {
    for (const key in responseData) {
      const oneOff = responseData[key];
      const oneOffObj = {
        id: key,
        oneOff: oneOff.oneoff,
        stacked: oneOff.stacked,
        dateCreated: oneOff.dateCreated,
        dateUpdated: oneOff.dateUpdated,
      };
      oneOffs.push(oneOffObj);
    }
  }
  return oneOffs;
}


// UPDATE A ONE-OFF - return true if update is successful, or error.
export async function updateAOneOff(uId, token, today, todayActual, oneOff) {
  console.log('starting updateAOneOff()');
  // console.log('starting updateAOneOff()', uId, token, today, todayActual, oneOff);

  const oId = oneOff.id;
  const updatedOneOff = {
    oneoff: oneOff.oneoff,
    stacked: oneOff.stacked,
    dateCreated: oneOff.dateCreated,
    dateUpdated: oneOff.dateUpdated,
  };
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/oneoffs/${oId}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify(updatedOneOff)
  });
  const responseData = await response.json();
  if (!response.ok) {
    console.log('responseData', responseData);
    const error = new Error(response.message || 'Failed to update a OneOff');
    throw error;
  } else {
    return true;
  }
}

// DELETE A ONE-OFF - return true if delete is successful, or error.
export async function deleteAOneOff(uId, token, today, oId ) {
  console.log('starting deleteOneProgram()');
  // console.log('starting deleteOneProgram()', uId, token, today, oId);
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/oneoffs/${oId}.json?auth=${token}`, {
    method: 'DELETE',
  });
  // response
  const responseData = await response.json();
  if (!response.ok) { 
    console.log(responseData);
    const error = new Error(response.message || 'Failed to delete a OneOff');
    throw error;
  } else {
    return true;
  }
}

